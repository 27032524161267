@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 4px !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 14px !important;
}

*::-webkit-scrollbar-thumb {
  background: #6e6e6e !important;
  border-radius: 14px !important;
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: #969696 #212f35 !important;
  }
}

.control-bar {
  background-color: transparent !important;
}

@media screen and (max-width: 35.5rem) {
  .control-bar {
    display: inline-flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    border-radius: 0.25rem !important;
    opacity: 1 !important;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.75rem 1.875rem 0px !important;
    flex-direction: row !important;
    height: 5rem !important;
    width: auto !important;
    position: static !important;
  }
}

.giki-container {
  @apply w-full;
}

.swiper-pagination {
  position: static !important;
}

.swiper-pagination-bullet-active {
  background: #323e53;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: #989ea9;
}

.ch-video {
  border-radius: 1rem;
}

.chakra-modal__body a {
  text-decoration: underline;
}

.category-menu > div {
  width: 100%;
}

@media (min-width: 1024px) {
  .giki-container {
    max-width: 100%;
  }
}
@media (min-width: 1280px) {
  .giki-container {
    max-width: 100%;
  }
}
@media (min-width: 1536px) {
  .giki-container {
    max-width: 100%;
  }
}

@media (min-width: 1920px) {
  .giki-container {
    max-width: 1920px;
  }
}

.style-scrollbar::-webkit-scrollbar {
  width: 3px !important;
}

.style-scrollbar::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 3px !important;
}

.style-scrollbar::-webkit-scrollbar-thumb {
  background: #ababab !important;
  border-radius: 3px !important;
}

.slider-control-bottomcenter {
  bottom: -30px;
  position: absolute;
}

.slider-control-bottomcenter > div {
  display: flex;
  gap: 10px;
}

.slider-list {
  gap: 10px;
}

.stretch-children .swiper-slide {
  align-self: stretch;
}

.chakra-avatar {
  overflow: hidden;
}

.chakra-avatar > img {
  border-radius: 0;
}

.swiper-wrapper .swiper-slide {
  align-self: stretch !important;
  height: 100% !important;
}
